@import './assets//styling/index.scss';

.App {
  background-color: var(--primary-bg-color);
}

.App {
  width: 100vw;
}

.App-admin {
  display: flex;
}

main {
  min-height: 60vh;
}

button {
  font-family: 'Inter', sans-serif;
}

input[type='submit'] {
  font-family: 'Inter', sans-serif;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  color: black;
}

a {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.btn {
  padding: 10px 1rem;
  // background: linear-gradient(45deg, #0aca60 -30px, rgb(70, 238, 75));
  background: linear-gradient(45deg, rgb(70, 238, 75) -30px, #0aca60);
  background-position: 0px 0px;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 0.35rem;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  display: flex;
  gap: 0.75rem;
  align-items: center;
  width: max-content;
  white-space: nowrap;

  &:hover {
    // background: linear-gradient(45deg, #00db0a 100px, #3ecefb);
    // background-color: var(--color--accent--dark);
    // background: rgb(70, 238, 75);
  }

  &-primary {
    background: linear-gradient(45deg, rgb(70, 238, 75) -30px, #0aca60);
    color: var(--white);
  }

  &-secondary {
    background: black;
    color: var(--white);

    &:hover {
      background: rgb(35, 35, 35);
    }
  }

  &-admin {
    background: var(--primary_blue);
    color: var(--white);
  }

  &-danger {
    background: rgb(234, 57, 57);
    color: var(--white);
  }

  // &-icon {
  //   margin-right: 0.5rem;
  //   display: flex;
  //   gap: 0.5rem;
  //   align-items: center;
  // }

  &-icon {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border-radius: 100%;
    width: 38px;
    aspect-ratio: 1/1;

    &:hover {
      background-color: #ececec;
    }
  }

  &-functional {
    background: transparent;
    color: black;
    transition: 300ms;
    height: max-content;
    padding: 8px;
    border: 1px solid #d7d7d7;

    display: flex;
    gap: 0.5rem;
    align-items: center;

    :hover {
      text-decoration: underline 3px;
    }
  }

  &--fullWidth,
  &--full {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
}

.btn--xs {
  font-size: 12px;
  font-weight: 600;
  padding: 0.25rem 0.5rem;
  min-width: max-content;
  border: 1px solid #d7d7d7;
}

.btn--small {
  font-size: 14px;
  font-weight: 600;
  padding: 0.5rem 0.75rem;
  min-width: max-content;
}

.btn-medium {
  padding: 0.75rem 1rem;
  font-size: 1rem;
}
.btn-large {
  padding: 20px 35px;
  font-size: 1rem;
}

.btn--secondary {
  background-color: transparent;
  color: rgb(61, 61, 61);
  border: 0.1rem solid rgb(189, 189, 189);
  margin-right: 0.5rem;
}

@media screen and (max-width: 500px) {
  .btn {
    padding: 0.5rem 0.85rem;
    font-size: 0.85rem;
  }
  .btn-large {
    padding: 15px 25px;
    font-size: 1rem;
  }
}

.btn--secondary:hover {
  background-color: rgb(231, 231, 231);
}

.page-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.section-container {
  background-color: var(--white);
  width: 90%;
  max-width: 1100px;
  border: 1px solid var(--secondary_lightGrey);
  border-radius: var(--border-radius-medium);
  margin-bottom: 2rem;
  padding: var(--padding-xlarge);

  @media screen and (max-width: 1000px) {
    padding: var(--padding-medium);
  }
  @media screen and (max-width: 500px) {
    padding: 1rem;
  }
}

.msg {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.msg-error svg {
  color: rgb(236, 52, 52);
  height: 20px;
  width: 20px;
}

.msg-validation svg {
  color: green;
  height: 20px;
  width: 20px;
}

input {
  min-height: 20px;
  padding: var(--padding-xsmall);
  padding-left: var(--padding-small);
  font-size: 16px;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 4px;
}

.input--error {
  border: 2px solid rgb(255, 0, 0);
  border-color: red;
}

.input-label {
  font-size: 14px;
}

select {
  min-height: 30px;
  padding: var(--padding-small);
  font-size: var(--font-size-xsmall);
  padding-right: 2rem;
}

option {
  padding: var(--padding-small);
}

.input-label-container {
  display: flex;
  flex-direction: column;
  max-width: 750px;
  position: relative;
}
.input-label-container select {
  max-width: fit-content;
}
.input-label-container label {
  margin-bottom: 0.5rem;
}
.input-label-container p {
  font-size: 14px;
  margin-bottom: 0.5rem;
}

.icon-verified {
  color: var(--primary-orange-color);
  margin-right: 0.2rem;
}

.bold {
  font-weight: 700;
}

.flex {
  display: flex;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-center-y {
  align-items: center;
}

.flex-center-x {
  justify-content: center;
}

.section-desc {
  max-width: 750px;
}

.text-container {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (max-width: 1000px) {
    h1 {
      font-size: var(--font-size-xlarge);
    }

    p {
      font-size: var(--font-size-medium);
    }
  }
  @media screen and (max-width: 500px) {
    h1 {
      font-size: var(--font-size-xlarge);
    }

    p {
      font-size: var(--font-size-small);
    }
  }
}

.pagination-container {
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;

  nav ul {
    // gap: 1rem;

    @media screen and (max-width: 500px) {
      gap: 5px;
    }
  }
}

.input-icon-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.hidden {
  height: 0;
  width: 0;
  visibility: 0;
  opacity: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}

h1,
h2 {
  font-weight: bold;
}

.hero-text {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0 1rem;

  &_img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 1rem;
    width: 100%;
    max-width: 1200px;
    aspect-ratio: 16/7;
    padding: 5rem;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: flex-end;
    position: relative;

    @media screen and (max-width: 750px) {
      border-radius: 0.5rem;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 1rem;
      z-index: 1;
    }

    & > * {
      z-index: 2;
    }

    h1 {
      font-size: 50px;
      max-width: 500px;
      line-height: 55px;
    }

    p {
      max-width: 500px;
      font-size: 20px;
    }

    @media (max-width: 620px) {
      padding: 2rem;
      min-height: 450px;

      h1 {
        font-size: 45px;
        line-height: 40px;
      }

      p {
        font-size: 18px;
      }
    }
  }
}
