.signup {
  width: 100%;
  display: flex;
  justify-content: center;

  &_modal {
    background-color: white;
    border-radius: 0.1rem;
    border: 1px solid #d4d4d4;
    border-radius: 10px;
    max-width: 600px;
    width: 100%;
    padding: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    position: relative;

    @media (max-width: 600px) {
      border-radius: 0;
      background-color: transparent;
      border: none;
      padding: 1rem;
    }

    input[type='text'] {
      border: 1px solid #d4d4d4;
      border-radius: 5px;
      // margin-bottom: 1rem;
    }

    label {
      text-align: start;
    }

    &-container {
      height: 100%;
    }

    &-form {
      text-align: start;
      width: 100%;

      h2 {
        font-size: 18px;
      }
    }

    &-section-desc {
      margin-bottom: 1rem;
    }
  }

  &-progress {
    display: flex;

    &_progressbar {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      &-step {
        height: 6px;
        width: 50px;
        background-color: #d4d4d4;
        border-radius: 5px;

        &--active {
          background-color: #fe9a3c;
        }
      }
    }

    &_btn {
      background-color: #fe9a3c;
      border: none;
      border-radius: 5px;
      color: white;
      // background-color: transparent;
      &--primary {
      }
    }
  }
}

.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;

  .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #4caf50;

    &::before {
      top: 3px;
      left: -2px;
      width: 30px;
      transform-origin: 100% 50%;
      border-radius: 100px 0 0 100px;
    }

    &::after {
      top: 0;
      left: 30px;
      width: 60px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in;
    }

    &::before,
    &::after {
      content: '';
      height: 100px;
      position: absolute;
      background: #ffffff;
      transform: rotate(-45deg);
    }

    .icon-line {
      height: 5px;
      background-color: #4caf50;
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;

      &.line-tip {
        top: 46px;
        left: 14px;
        width: 25px;
        transform: rotate(45deg);
        animation: icon-line-tip 0.75s;
      }

      &.line-long {
        top: 38px;
        right: 8px;
        width: 47px;
        transform: rotate(-45deg);
        animation: icon-line-long 0.75s;
      }
    }

    .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(76, 175, 80, 0.5);
    }

    .icon-fix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: #ffffff;
    }
  }
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

$color-red: #f86;
$circle-size: 60px;

.circle,
.circle-border {
  width: $circle-size;
  height: $circle-size;
  border-radius: 50%;
}

.circle {
  z-index: 1;
  position: relative;
  background: white;
  transform: scale(1);
  animation: success-anim 700ms ease;
}

.circle-border {
  z-index: 0;
  position: absolute;
  transform: scale(1.1);
  animation: circle-anim 400ms ease;
  background: $color-red;
}

@keyframes success-anim {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes circle-anim {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1.1);
  }
}

.error::before,
.error::after {
  content: '';
  display: block;
  height: 4px;
  background: $color-red;
  position: absolute;
}

.error::before {
  width: 40px;
  top: 48%;
  left: 16%;
  transform: rotateZ(50deg);
}

.error::after {
  width: 40px;
  top: 48%;
  left: 16%;
  transform: rotateZ(-50deg);
}
