@font-face {
  font-family: 'password';
  font-style: normal;
  font-weight: 400;
  src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
}

.user-profile {
  background-color: white;
  padding: 0.75rem 1.5rem;
  margin-top: 1rem;
  border: 1px solid #d7d7d7;

  @media screen and (max-width: 950px) {
    background-color: transparent;
  }

  @media screen and (max-width: 639px) {
    padding: 0 1rem;
  }

  &_header {
    padding-bottom: 0.75rem;
    border-bottom: solid 1px #eeeeee;
    display: flex;
    gap: 0.5rem;

    justify-content: flex-end;

    @media screen and (max-width: 639px) {
      padding-top: 1rem;
    }
  }

  &_btn {
    &-verify {
      font-size: 14px;
      font-weight: 500;
    }
  }

  &_input {
    &-container {
      display: flex;
      margin-top: 2rem;
      &:not(:last-child) {
        border-bottom: solid 1px #eeeeee;
      }
      gap: 4rem;
      padding-bottom: 2rem;

      @media screen and (max-width: 639px) {
        flex-direction: column;
        gap: 1rem;
      }
    }

    &-text {
      width: 100%;
      padding-right: 2rem;

      @media screen and (max-width: 639px) {
        width: 100%;
        padding-right: 0;
      }

      h3 {
        font-weight: 700;
        font-size: 16px;
      }

      p {
        font-size: 14px;
        color: #8f8f8f;
      }
    }

    &-inputs {
      width: 100%;

      div {
        margin-bottom: 0.5rem;
      }

      input {
        border: 1px solid #e2e2e2;
        border-radius: 3px;

        @media screen and (max-width: 639px) {
          width: 100%;
        }
      }
    }
  }
}

input.key {
  font-family: 'password';
  // width: 100px; height: 16px;
}
