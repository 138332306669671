.articles {
  &-page {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    width: 100%;
    padding: 0 1rem;

    nav {
      margin-bottom: 1rem;

      @media screen and (max-width: 500px) {
        margin-bottom: 0.4rem;
      }

      ul {
        list-style-type: none;
        display: flex;
        font-size: 28px;
        font-weight: 600;
        color: rgb(170, 170, 170);

        @media screen and (max-width: 500px) {
          font-size: 16px;
        }

        li {
          margin: 0 0.35rem;
          font-size: 28px;
          font-weight: 600;
          cursor: pointer;

          @media screen and (max-width: 500px) {
            font-size: 16px;
          }
        }

        a {
          text-decoration: none;
          color: rgb(170, 170, 170);

          &:hover {
            color: rgb(119, 119, 119);
          }
        }
      }
    }
  }

  &-wrapper {
    padding: 0 2rem;

    @media screen and (max-width: 500px) {
      padding: 0 0;
    }
  }

  &_crumb--active {
    color: rgb(51, 51, 51) !important;
    cursor: auto !important;
  }

  &_heading {
    font-size: 48px;
    margin-bottom: 2rem;
  }

  &-section {
    flex: 3 1;
    width: 100%;

    @media screen and (max-width: 950px) {
      margin-bottom: 2rem;
    }
  }

  &_container {
    // display: flex;
    // gap: 1rem;
    width: 100%;

    @media screen and (max-width: 950px) {
      align-items: center;
      flex-direction: column;
    }
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    flex-wrap: wrap;
    gap: 1rem;

    @media screen and (max-width: 950px) {
    }

    @media screen and (max-width: 950px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 750px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 450px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
