.info-text-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 0 2rem;
  text-align: center;

  h4 {
    font-size: var(--font-size-medium);
    font-weight: 400;
  }

  h1 {
    font-size: var(--font-size-large);
  }

  p {
    font-size: var(--font-size-small);
  }

  @media screen and (max-width: 500px) {
    padding: 0 1rem;

    h1 {
      font-size: var(--font-size-medium);
    }
    h4 {
      font-size: var(--font-size-small);
    }

    p {
      font-size: var(--font-size-xsmall);
    }
  }
}
