.notification-wrapper {
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 300px;
    z-index: 10000;
}

.notification-item {
    box-shadow: 0 0 7px rgba(0, 0 ,0 , 0.3);
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px;
    animation: SlideLeft 0.4s;
    animation-fill-mode: forwards;
    width: 300px;
    background-color: white;

    p {
        padding: 10px;
    }

    &_bar {
        height: 7px;
    }
    
    &--success {
        // background-color: rgb(39, 145, 39);
        .notification-item_bar {
            background-color: rgb(60, 212, 60);
        }
        
    }
    
    &--error {
        // background-color: red;
        .notification-item_bar {
            background-color: rgb(231, 62, 62);

        }
    }

    &--exit {
        animation: SlideRight 0.4s;
        animation-fill-mode: forwards;
    }
}

@keyframes SlideLeft {
    0% {
        margin-left: 120%;
    }

    100% {
        margin-left: 0;
    }
}
@keyframes SlideRight {
    0% {
        margin-left: 0;
    }
    
    100% {
        margin-left: 120%;
    }
}