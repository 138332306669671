.header_navigation {
  &-container {
    display: flex;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    transition: background-color 0.3s ease-in-out;
    height: 100%;

    @media screen and (max-width: 1200px) {
      // padding: 1rem 2rem;
    }

    nav {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 500px) {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr 1fr;
        gap: 0.5rem;
        row-gap: 1rem;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .desktop-nav,
      .desktop-nav li {
        height: 100%;
      }
    }
  }

  &-logo {
    height: 40px;

    @media screen and (max-width: 700px) {
      height: 30px;
    }
  }
}

.desktop-nav {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  position: relative;
  align-items: center;
  margin-left: auto;

  &_login {
    position: relative;

    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
      background: white;
      position: absolute;
      top: 80%;
      right: 0;
      height: max-content !important;
      border: 1px solid rgb(231, 231, 231);
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.24);
      padding: 5px;
      background-color: rgb(249, 249, 249);
      z-index: 100;
    }

    li {
      color: black;
      display: block;
    }

    ul,
    li {
      list-style: none;
      margin: 0;
    }

    button {
      width: 100%;
      text-align: left;
    }

    a,
    button {
      text-decoration: none;
      // color: black;
      padding: 12px;
      justify-content: flex-start !important;
      border-radius: 10px;
      transition: 0.2s ease-in-out;

      &:hover {
        // background-color: white;
      }
    }
  }
}

.desktop-nav_login {
  display: flex;
  align-items: center;
  height: 100%;
}
.desktop-nav_login-icon {
  height: 24px;
  cursor: pointer;
  transition: 150ms ease-in-out;

  &:hover {
    color: rgb(124, 124, 124);
  }
}

.header_navigation-container nav ul li {
  text-decoration: none;
  list-style: none;
}

.nav-link {
  color: black;
  text-decoration: none;
  font-size: 18px;
  padding: 10px;
  min-width: 80px;
  display: flex;
  justify-content: center;
  transition: 150ms ease-in-out;
  border-radius: 5px;
  position: relative;
  height: 100%;

  &:after {
    content: '';
    transition: 0.2s ease-in-out;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 0%;
    height: 2px;
    background-color: black;
  }

  &_sidebar {
    display: flex;
    justify-content: flex-start;
    padding: 0;

    a {
      padding: 10px;
      width: 100%;
      height: 100%;
      text-decoration: none;
      color: rgb(32, 32, 32);
    }

    &--active {
      &:after {
        width: 100%;
      }
    }
  }

  a {
    text-decoration: none;
    color: #3e3e3e;
  }
}

.nav-link:hover {
  //   background-color: rgb(240, 240, 240);
  cursor: pointer;

  &:after {
    width: 100%;
  }
}

.mobile-nav {
  margin-left: auto;
  height: 100%;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 1000px) {
  .mobile-nav,
  .mobile-nav_sidepanel,
  .mobile-nav_sidepanel-overlay {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .desktop-nav {
    display: none;
  }
}

.hamburger {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.hamburger:hover .line {
  background-color: var(--primary-orange-color);
}

.line {
  height: 3px;
  width: 100%;
  background-color: black;
  margin-bottom: 0.3rem;
  transition: all 200ms ease-in-out;

  // &--white {
  //     color: white;
  // }

  &:nth-child(2) {
    width: 70%;
  }
}

.mobile-nav_sidepanel {
  background-color: var(--primary-bg-color);
  position: absolute;
  /* display: none; */
  top: 0;
  width: 250px;
  height: 100vh;
  z-index: 10;
  right: 0;
  padding: 4rem 1rem 0 1rem;
}

.mobile-nav_sidepanel-list {
  display: flex;
  flex-direction: column;
  z-index: 10;
  // width: 100;
}

.mobile-nav_sidepanel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  background-color: rgb(0, 0, 0, 0.4);
}

.header_search {
  margin-left: 2rem;
  &-input {
    border-radius: 2rem;
    padding-left: 2rem;
    min-width: 320px;

    &-icon {
      position: absolute;
      left: 10px;
    }

    &-label {
      position: absolute;
      left: 35px;
    }

    @media (max-width: 500px) {
      width: 100%;
    }
  }

  @media (max-width: 750px) {
    margin-left: 0.5rem;
  }
  @media (max-width: 500px) {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: 5;
    margin-left: 0;
  }
}

.navigation {
  @media (max-width: 500px) {
    grid-column: 4/5;
  }
}
