.sell-calculator {
  width: 100%;
  border-radius: 1rem;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  // box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(169, 225, 169) 0px 0px 0px 3px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(186 190 210 / 20%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 3px 9px 0px, rgb(0 0 0 / 8%) 0px 2px 5px 0px;
  margin-top: 2rem;

  h2 {
    font-size: 20px;
  }

  h3 {
    font-weight: 500;
    color: rgb(179, 179, 179);
    font-size: 18px;
  }

  &_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    align-items: center;

    p {
      margin: 0;
      font-weight: 600;
      font-size: 24px;
    }
  }

  &_price {
    font-size: 48px !important;
    margin-bottom: 5px !important;

    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
  }
  &_content {
    display: flex;
    justify-content: space-around;
  }

  input[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 100%;
    height: auto;
    border: none;
  }

  /***** Track Styles *****/
  /***** Chrome, Safari, Opera, and Edge Chromium *****/
  input[type='range']::-webkit-slider-runnable-track {
    background: #000000;
    height: 0.5rem;
    border-radius: 5px;
  }

  /******** Firefox ********/
  input[type='range']::-moz-range-track {
    background: #000000;
    height: 0.5rem;
  }

  /***** Thumb Styles *****/
  /***** Chrome, Safari, Opera, and Edge Chromium *****/
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -8px; /* Centers thumb on the track */
    background-color: rgb(51, 169, 55);
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
  }

  /***** Thumb Styles *****/
  /***** Firefox *****/
  input[type='range']::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/
    background-color: #5cd5eb;
    height: 2rem;
    width: 1rem;
  }

  /***** Focus Styles *****/
  /* Removes default focus */
  input[type='range']:focus {
    outline: none;
  }

  /***** Chrome, Safari, Opera, and Edge Chromium *****/
  input[type='range']:focus::-webkit-slider-thumb {
    border: 1px solid #053a5f;
    outline: 3px solid #053a5f;
    outline-offset: 0.125rem;
  }

  /******** Firefox ********/
  input[type='range']:focus::-moz-range-thumb {
    border: 1px solid #053a5f;
    outline: 3px solid #053a5f;
    outline-offset: 0.125rem;
  }
}
