.admin-allusers {
  display: flex;
  flex-direction: column;
  position: relative;

  input[type='text'] {
    border-radius: 5px;
  }

  &_heading-container {
    margin-bottom: 5px;

    p {
      color: rgb(107, 107, 107);
    }
  }

  form {
    width: 100%;
    max-width: 400px;
  }

  &_pagiantion {
    margin-top: auto;

    display: flex;
    align-items: center;
    flex-direction: column;

    p {
      margin-top: 0.5rem;
    }
  }

  &_user {
    &-info {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;
    }
  }
}
