.hero {
  display: flex;
  justify-content: center;
  padding-top: 0rem;

  &_column {
    max-width: 1200px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    z-index: 1;
    padding-top: 3rem;
    gap: 2rem;

    @media (max-width: 1000px) {
      grid-template-columns: 1fr 0.5fr;
    }

    @media (max-width: 750px) {
      grid-template-columns: 1fr;
    }

    &-text {
      padding: 1rem;
      padding-top: 4rem;
      max-width: 500px;

      h1 {
        font-size: 55px;
        line-height: 64px;
      }

      @media (max-width: 470px) {
        padding-top: 0rem;

        h1 {
          font-size: 35px;
          line-height: 40px;
        }
      }

      @media (max-width: 1000px) {
        padding-top: 2rem;
      }

      & > * {
        margin-bottom: 2rem;
      }

      &--description {
        font-size: 18px;
        line-height: 25px;
      }
    }

    &-imgs {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(12, 1fr);
      gap: 2rem;
      min-height: 750px;
      padding-right: 1rem;

      @media (max-width: 1000px) {
        grid-template-columns: repeat(1, 1fr);
      }

      @media (max-width: 750px) {
        display: none;
      }
    }
  }

  &_img {
    background-position: center;
    background-size: cover;
    border-radius: 1.2rem;

    &-top-left {
      background-image: url('https://sbb-bucket.fra1.digitaloceanspaces.com/imgs/about-header.jpg');
      grid-row: 1 / 5;
      grid-column: 1 / 2;
    }
    &-bottom-left {
      background-image: url('https://sbb-bucket.fra1.digitaloceanspaces.com/imgs/CJS08367.jpg');
      grid-row: 5 / 13;
      grid-column: 1 / 2;
    }
    &-right {
      background-image: url('https://sbb-bucket.fra1.digitaloceanspaces.com/imgs/start-img.jpg');
      grid-row: 2 / 11;
      grid-column: 2 / 3;

      @media (max-width: 1000px) {
        display: none;
      }
    }
  }
}
