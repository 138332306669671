.products-page {
  display: flex;
  flex-direction: column;
}

.products-search {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;

  @media screen and (max-width: 500px) {
    padding: 0;
  }
}

.products-container {
  width: 100%;
  max-width: 1110px;
  padding: 2rem;
  border-radius: var(--border-radius-large);
  border: 1px solid var(--secondary_lightGrey);
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 500px) {
    padding: 1rem;
    border-radius: 0;
  }
}

.products {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  justify-content: center;
  margin-bottom: 4rem;

  @media screen and (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 750px) {
    grid-template-columns: repeat(1, 1fr);
    // grid-template-columns: repeat(auto-fill, 270px);
  }
}

.products-pagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.products-pagination nav ul {
  gap: 1rem;

  @media screen and (max-width: 500px) {
    gap: 5px;
  }
}
