.homepage {
  min-height: 100vh;
  width: 100vw;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;

  &_image {
    border-radius: 1rem;
    width: 90%;
    // max-width: 575px;
    // height: 750px !important;
    aspect-ratio: 15 / 16;

    @media (max-width: 1000px) {
      // display: none !important;
      height: 450px !important;
    }

    & > * {
      border-radius: 1rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
  }

  &-info {
    display: flex;
    justify-content: center;
    // margin-top: 6rem;

    &_inner {
      display: grid;
      grid-template-columns: 1fr 1fr;
      height: 200vh;
      max-width: 1200px;
      justify-content: center;
      gap: 4rem;

      @media (max-width: 900px) {
        grid-template-columns: 1fr;
        height: max-content;
      }
    }

    &_text {
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;

      @media (max-width: 900px) {
        height: max-content;
      }
    }

    &_image {
      background-color: black;
      aspect-ratio: 15 / 16;
      width: 520px;
      background-image: url('../../assets/imgs/category-imgs/cykel.jpg');
      // background-image: url('../../assets/imgs/category-imgs/cycling.jpg');
      background-position: center;
      border-radius: 1rem;
      margin-top: 4rem;

      @media (max-width: 1000px) {
        aspect-ratio: 10 / 13;
        width: 420px;
      }
    }

    &_left {
      position: relative;
      width: 100%;
      height: 100vh;
      display: grid;
      align-items: center;
      justify-content: center;

      @media (max-width: 900px) {
        display: none;
      }
    }

    &_right {
      height: max-content;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      h1 {
        font-size: 24px;
        color: #9c9c9c;
        font-weight: 500;

        @media (max-width: 620px) {
          font-size: 22px;
        }
      }

      h2 {
        font-size: 46px;
        max-width: 470px;
        line-height: 55px;

        @media (max-width: 620px) {
          font-size: 35px;
          margin-bottom: 1rem;
          max-width: 270px;
          line-height: 42px;
        }
      }

      p {
        font-size: 18px;
        max-width: 470px;

        @media (max-width: 620px) {
          font-size: 18px;
        }
      }
    }

    @media (max-width: 1000px) {
      margin-top: 4rem;
    }

    &--inner {
      width: 100%;
      max-width: 1200px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      @media (max-width: 1000px) {
        grid-template-columns: repeat(1, 1fr);
        padding: 0 1rem;
      }
    }

    &-btn {
      min-width: 50px;
      aspect-ratio: 1 / 1;
      border-radius: 50%;
      cursor: pointer;

      background-color: rgb(238, 238, 238);
      border: 1px solid rgb(190, 190, 190);

      &_container {
        display: flex;
        gap: 16px;
        margin-top: auto;
      }
    }

    .swiper {
      height: 100%;
    }
  }

  &_textbox {
    padding: 6rem 2rem 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media (max-width: 1000px) {
      grid-row: 1 / 2;
      padding: 2rem 0;
      width: 100%;
    }

    @media (max-width: 1000px) {
      gap: 1rem;
    }

    h1 {
      font-size: 28px;
      color: #9c9c9c;
      font-weight: 500;

      @media (max-width: 620px) {
        font-size: 22px;
      }
    }

    h2 {
      font-size: 50px;

      @media (max-width: 620px) {
        font-size: 35px;
        margin-bottom: 1rem;
        max-width: 270px;
      }
    }

    p {
      font-size: 22px;

      @media (max-width: 620px) {
        font-size: 18px;
      }
    }
  }
}

.categories-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  margin-top: 0.5rem;
  max-width: 1200px;
  padding: 1rem;
  width: 100%;
  border-radius: 1rem;
  background-color: #eeeeee;
  position: relative;

  @media screen and (max-width: 600px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 1fr;
  }

  &:before,
  &:after {
    // content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    border-radius: 1rem;
  }
  &:before {
    left: 0;
    top: 0;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 90%, rgba(234, 234, 234, 1) 98%);
  }
  &:after {
    left: 0;
    top: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 90%, rgba(234, 234, 234, 1) 98%);
  }

  .sc-dkzDqf,
  .coWKnQ {
    display: flex;
    justify-content: space-around;
  }

  div:nth-child(n + 5) {
    display: none;
  }

  @media (min-width: 800px) {
    div:nth-child(n + 5) {
      display: flex;
    }
    div:nth-child(n + 7) {
      display: none;
    }
  }

  // @media (max-width: 1000px) {
  //   grid-template-columns: repeat(4, 1fr);
  //   grid-template-rows: 1fr 1fr;

  //   div:nth-child(n + 9) {
  //     display: none;
  //   }
  // }
  // @media (max-width: 750px) {
  //   grid-template-columns: repeat(3, 1fr);
  //   grid-template-rows: 1fr 1fr;

  //   div:nth-child(n + 7) {
  //     display: none;
  //   }
  // }

  // @media (max-width: 620px) {
  //   border-radius: 0;
  // }
}

.category-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  // margin-top: 2em;
  // padding: 0 1rem;
  // margin-bottom: 2rem;

  @media (max-width: 620px) {
    margin-top: 4em;
    padding: 0;
  }

  h2 {
    text-align: left;
    margin-bottom: 0;
  }
}

.category-section:first-child > button {
  margin-left: auto;
}

.articles-home {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.articles-container {
  max-width: 1200px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;

  @media screen and (max-width: 1100px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 1100px) {
  .articles-container :nth-child(n + 4) {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .articles-container :nth-child(n + 3) {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .articles-container :nth-child(n + 3) {
    display: flex;
    flex-direction: column;
  }

  .articles-container :nth-child(n + 4) {
    display: flex;
    flex-direction: column;
  }
  .articles-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .articles {
    padding: 1rem;
  }

  .categories-container {
    padding: 0.75rem 0.75rem;

    @media (max-width: 620px) {
      padding: 16px;
    }
  }
}
