.drawer {
  height: 100vh;
  position: absolute;
  top: 0;
  max-width: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--primary-bg-color);

  &-overlay {
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    background-color: rgba(0, 0, 0, 0.35);

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 300;

    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &-background-click {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &-header {
    padding: 30px;
    border-bottom: 1px solid rgb(229, 229, 229);
    margin-bottom: 0.5rem;

    h2 {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 5px;
    }

    &_image {
      width: 70px;
      aspect-ratio: 1/1;
      background-color: grey;
      border-radius: 50%;
      margin-bottom: 0.75rem;
    }
  }

  &-middle {
    padding: 10px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &_item {
      h2 {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0.5rem;
      }
    }

    &_list {
      &-heading {
        font-size: 13px;
        color: #616161;
      }
      li {
        display: flex;
        gap: 5px;
        align-items: center;
        font-size: 14px;
        color: #bbbbbb;

        h4 {
          font-weight: 400;
          font-size: 16px;
        }

        .my-team {
          font-size: 12px;
          color: rgb(109, 109, 109);
        }

        .progress {
          margin-left: auto;
        }
      }

      &-store {
        margin-bottom: 10px;

        li {
          justify-content: space-between;
        }
      }
    }
  }
}
