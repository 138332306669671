.category-card {
  width: 100%;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: flex-end;
  padding: 1rem;
  background-position: center;
  background-size: cover;
  background-repeat: none;
  border-radius: var(--border-radius-small);
  color: none;
  font-size: 1.5rem;
  cursor: pointer;
  position: relative;
  color: white;
  width: 175px;

  @media (max-width: 620px) {
    padding: 5px;
    width: 100%;
  }

  &:after {
    border-radius: var(--border-radius-small);
    content: '\A';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 80%);
    transition: all 0.2s ease-in-out;
    z-index: 8;
    opacity: 0.4;

    // @media (max-width: 620px) {
    //   opacity: 0.6;
    // }
  }

  h3 {
    z-index: 10;
    font-size: 18px;
    transition: 0.3s ease-in-out;
    display: block;
    z-index: 10;
    height: auto;
    width: auto;
    opacity: 1;
    font-weight: 600;
    transition: all 0.4s ease-in-out;
    // transform: translateY(20px);

    // @media (max-width: 620px) {
    //   opacity: 0.95;
    // }
  }

  &:hover {
    // box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(149, 214, 149) 0px 0px 0px 4px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(186 190 210 / 20%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 3px 9px 0px, rgb(0 0 0 / 8%) 0px 2px 5px 0px;

    &:after {
      opacity: 0.6;
    }

    h3 {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}
