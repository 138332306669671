.footer {
  background: black;
  color: white;
  padding: 6rem 2rem;
  display: flex;

  @media screen and (min-width: 950px) {
    justify-content: center;
  }

  @media screen and (max-width: 475px) {
    padding: 3rem 1rem;
  }

  &_inner {
    @media screen and (min-width: 950px) {
      display: flex;
      gap: 4rem;
    }
  }

  &_newsletter {
    max-width: 500px;
    margin-bottom: 2rem;

    h2 {
      font-weight: 500;
      font-size: 36px;
      margin-bottom: 1.5rem;
      line-height: 40px;
    }

    p {
      font-size: 16px;
      margin-bottom: 1.5rem;
      color: rgb(206, 206, 206);
    }

    input[type='text'] {
      border-radius: 10px;
      height: 40px;
      width: 100%;
      color: black;
      font-size: 16px;

      @media screen and (min-width: 950px) {
        height: 50px;
      }
    }

    &-submit {
      border-radius: 10px;
      height: 40px;
      border: none;
      position: absolute;
      right: 0;
      background-color: var(--primary-orange-color);
      color: white;
      font-size: 14px;
      cursor: pointer;
      transition: background-color 200ms ease-in-out;

      &:hover {
        background-color: rgb(236, 182, 80);
      }

      @media screen and (min-width: 950px) {
        height: 50px;
        font-size: 16px;
        // font-weight: 600;
        padding: 0 1rem;
      }
    }

    &-input {
      position: relative;
      display: flex;
      align-items: center;
    }

    label {
      color: rgb(206, 206, 206);
    }
  }

  &_linklist {
    list-style-type: none;
    margin-bottom: 2rem;
    max-width: 200px;

    &:has(a:hover) a:not(:hover) {
      opacity: 0.5;
    }

    a {
      color: rgb(206, 206, 206);
      text-decoration: none;
      transition: 200ms ease-in;

      &:hover {
        color: white;
      }
    }

    &-container {
      max-width: none;
      display: flex;
      flex-wrap: wrap;
      @media screen and (max-width: 950px) {
        column-gap: 4rem;
        row-gap: 1rem;
      }
    }

    &-innercontainer {
      @media screen and (min-width: 950px) {
        margin-right: 4rem;
      }

      h3 {
        font-size: 24px;
        font-weight: 500;
      }
    }

    &-icons {
      display: flex;
      align-items: center;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        margin-right: 1rem;
        display: flex;
        background-color: #3d3d3d;
        border-radius: 50%;
        transition: 200ms ease-in-out;

        &:hover {
          background-color: #292929;
        }
      }
    }

    &-item {
      margin-top: 1rem;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}
