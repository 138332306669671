.overlay-button {
  position: fixed;
  bottom: 20px;
  right: 40px;
  background-color: black;
  color: white;
  padding: 0.7rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: 150ms ease-in-out;
  z-index: 100;
  display: flex;
  align-items: center;
  text-decoration: none;
}
