.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.toggle-label-container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 1rem;
  //     label {
  //       cursor: pointer;
  //       text-indent: -9999px;
  //       width: 200px;
  //       height: 100px;
  //       background: grey;
  //       display: block;
  //       border-radius: 100px;
  //       position: relative;
  //     }

  //     label:after {
  //       content: '';
  //       position: absolute;
  //       top: 5px;
  //       left: 5px;
  //       width: 90px;
  //       height: 90px;
  //       background: #fff;
  //       border-radius: 90px;
  //       transition: 0.3s;
  //     }

  //     input:checked + label {
  //       background: #bada55;
  //     }

  //     input:checked + label:after {
  //       left: calc(100% - 5px);
  //       transform: translateX(-100%);
  //     }

  //     label:active:after {
  //       width: 130px;
  //     }
}
