.product-card {
  cursor: pointer;

  &:hover &_img {
    transform: scale(1.05);
  }
  &_img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    transition: transform 0.4s;

    &--error {
      height: 50%;
      width: 50%;
      object-fit: contain;
    }
    &-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 5px;
      height: 100%;
      height: 320px;
      border: 1px solid rgb(213 213 213);
      overflow: hidden;
      position: relative;
      background-color: #dadada;

      p {
        color: white;
        font-size: 24px;
        font-weight: 600;
        margin-top: 1rem;
      }

      img {
      }
    }
  }

  &_img {
  }
}
