.admin {
  width: 100%;
  display: flex;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }

  &-section {
    padding: 2rem;
    overflow-y: scroll;
    max-height: 100vh;

    @media screen and (max-width: 1000px) {
      padding: 1rem;
    }
  }

  &-mobile-nav {
    .hamburger .line {
      background-color: white;
    }
  }

  &_error {
    color: red;
  }

  main {
    padding: 2rem;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;

    @media screen and (max-width: 500px) {
      padding: 1rem;
    }
  }

  h1 {
    font-size: var(--font-size-large);
    margin-bottom: 1rem;
  }

  textarea {
    min-height: 100px;

    font-size: 16px;
    padding: 0.3rem;
  }

  &-input {
    border-radius: 5px;
  }

  &_pagination {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 0.5rem;
  }
}

.admin-table {
  background-color: rgb(250, 250, 250);
  border: 1px solid rgb(221, 221, 221);
  border-radius: 10px;
  border-spacing: 0;
  width: 100%;

  thead {
    background-color: rgb(233, 233, 233);
    width: 100%;

    tr {
      background-color: rgb(233, 233, 233);

      th {
        padding: 0.5rem 0.5rem;
        background-color: rgb(233, 233, 233);
        font-size: 14px;
        text-align: left;
        min-width: 70px;
      }
    }
  }

  tbody {
    tr {
      td {
        border-bottom: 1px solid rgb(221, 221, 221);
        padding: 0.5rem 0.5rem;
        font-size: 14px;
        background-color: rgb(250, 250, 250);
      }
    }
  }

  &_bold {
    font-weight: 600;
  }

  &_btn {
    border: none;
    background-color: transparent;
    border-radius: 100%;
    padding: 7px;
    transition: 150ms ease-in-out;
    cursor: pointer;
    font-weight: 600;

    &:hover {
      background-color: rgb(228, 228, 228);
    }

    &--save {
      border-radius: 5px;
    }
  }

  &_icon {
    width: 20px;
    height: 20px;
  }

  &_message {
    width: 100%;
    height: 50px;
  }

  &_checkbox {
    width: 20px;
    height: 20px;
  }

  &_sorting-title {
    position: relative;

    &-icon {
      margin-left: 5px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: none;

      &--active {
        display: inline;
      }

      &--rotate {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}

.admin-inner {
  width: 100%;
}

.admin-block {
  background-color: var(--white);
  width: 100%;
  max-width: 1000px;
  border: 1px solid var(--secondary_lightGrey);
  border-radius: var(--border-radius-medium);
  margin-bottom: 2rem;
  padding: var(--padding-xlarge);

  @media screen and (max-width: 1000px) {
    padding: var(--padding-medium);
  }
}

.admin-page-heading {
  font-size: var(--font-size-xlarge);
  margin-bottom: 1rem;
}

.admin-btn {
  background-color: var(--primary_blue);
  color: var(--white);
  border: none;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  width: max-content;

  &-container {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;

    @media screen and (max-width: 500px) {
      position: static;
      margin-top: 1rem;
      justify-content: flex-end;

      input[type='submit'] {
        border-radius: 0.5rem;
      }

      button {
        min-width: 50%;
      }
    }
  }

  &--primary {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0.5rem 0 0;
    height: 50px;
    min-width: 80px;
    font-size: 16px;
  }

  &--secondary {
    font-size: 16px;
    background-color: transparent;
    color: #333;
    border: none;
    margin-right: 1rem;
    cursor: pointer;

    &:hover {
      color: rgb(151, 151, 151);
    }
  }

  &--error {
    background-color: rgb(197, 17, 17);

    &:hover {
      background-color: rgb(209, 18, 18);
    }
  }
}

.admin-btn:hover {
  color: rgb(224, 224, 224);
}

.admin-btn_round {
  padding: 0;
  height: 40px;
  width: 40px;
  border-radius: var(--border-radius-round);
}

.admin-btn-danger {
  background-color: rgb(228, 32, 32);
  padding: var(--padding-small);
  font-size: var(--font-size-small);
  max-height: 42px;
}

.admin-btn-input {
  padding: var(--padding-small);
  font-size: var(--font-size-small);
  max-height: 42px;
}

.admin-input_container {
  position: relative;
  width: max-content;
  label {
    display: block;
  }

  input[type='text'] {
    border-radius: 5px;
  }
  @media screen and (max-width: 1000px) {
    width: 100%;
    input[type='text'],
    input[type='password'] {
      width: 100%;
    }
  }
}

.admin-dashboard {
  width: calc(90% - 200px);
}

.admin-dashboard_stats {
  width: 100%;
  padding-top: 1rem;
  margin-bottom: 2rem;
}

.admin-dashboard_stats-container {
  margin-top: 1rem;
  display: flex;
  gap: 4rem;
}

.admin-dashboard_stats-item {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.admin-dashboard_stats-icon {
  background-color: var(--primary_lightblue_010);
  height: 50px;
  width: 50px;
  border-radius: var(--border-radius-round);
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-dashboard_stats-icon svg {
  height: 40%;
}

.admin-dashboard_stats-text p {
  font-weight: 700;
  font-size: var(--font-size-medium);
}
.admin-dashboard_stats-text h3 {
  /* font-weight: 500; */
  color: var(--primary_grey);
}

.admin-dashboard_alertbar {
  width: 100%;
  padding-top: 1rem;
}

.admin-dashboard_alertbar-header {
  display: flex;
  align-items: center;
}

.admin-dashboard_alertbar-header label {
  margin-left: 1rem;
  margin-right: 0.2rem;
}

.admin-dashboard_alertbar-header button {
  margin-left: auto;
}

.admin-dashboard_alertbar-inputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  /* max-height: max-content; */
}

.admin-dashboard_alertbar-input {
  /* max-height: 42px; */
  display: flex;
  align-items: center;
  width: 100%;

  &-btn {
    &--secondary {
      background-color: transparent;
      color: #333;
      font-size: 16px;
      position: absolute;
      top: 10px;
      right: 90px;

      &:hover {
        color: rgb(151, 151, 151);
      }
    }
  }
}

.admin-dashboard_alertbar-input input[type='text'] {
  /* border-right: none; */
  width: 100%;
}

.section-admin {
  width: 100%;
  max-width: none;
  padding: 1rem;

  &_heading-container {
    margin-bottom: 5px;
    display: flex;

    h2 {
      font-size: 20px;
    }

    p {
      color: rgb(155, 155, 155);
    }
  }
}
