.work {
  h1 {
    font-size: 34px;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 0.5rem;
  }

  &-articles {
    margin-top: 2rem;
    h2 {
      margin-bottom: 1rem;
    }

    h3 {
      margin-bottom: 0.5rem;
    }

    &-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 1rem;
    }
  }

  &_articles-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
}
