@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap');
@import 'react-alice-carousel/lib/alice-carousel.css';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import url('https://rsms.me/inter/inter.css');

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}

@layer base {
  :root {
    --background: 60, 8%, 95%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 0 0% 0%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }
}

:root {
  --white: rgb(255, 255, 255);
  --white_010: rgba(255, 255, 255, 0.1);
  --white_016: rgba(255, 255, 255, 0.16);
  --white_025: rgba(255, 255, 255, 0.25);
  --white_030: rgba(255, 255, 255, 0.3);
  --white_036: rgba(255, 255, 255, 0.36);
  --white_040: rgba(255, 255, 255, 0.4);
  --white_050: rgba(255, 255, 255, 0.5);
  --white_075: rgba(255, 255, 255, 0.75);
  --white_090: rgba(255, 255, 255, 0.9);
  --white_097: rgba(255, 255, 255, 0.97);
  --white_transparent: rgba(255, 255, 255, 0);
  --primary_lightGrey: rgb(244, 245, 248);
  --primary_lightGrey_010: rgba(244, 245, 248, 0.1);
  --primary_lightGrey_016: rgba(244, 245, 248, 0.16);
  --primary_lightGrey_025: rgba(244, 245, 248, 0.25);
  --primary_lightGrey_030: rgba(244, 245, 248, 0.3);
  --primary_lightGrey_036: rgba(244, 245, 248, 0.36);
  --primary_lightGrey_040: rgba(244, 245, 248, 0.4);
  --primary_lightGrey_050: rgba(244, 245, 248, 0.5);
  --primary_lightGrey_075: rgba(244, 245, 248, 0.75);
  --primary_lightGrey_090: rgba(244, 245, 248, 0.9);
  --primary_lightGrey_097: rgba(244, 245, 248, 0.97);
  --secondary_lightGrey: rgb(226, 227, 230);
  --secondary_lightGrey_010: rgba(226, 227, 230, 0.1);
  --secondary_lightGrey_016: rgba(226, 227, 230, 0.16);
  --secondary_lightGrey_025: rgba(226, 227, 230, 0.25);
  --secondary_lightGrey_030: rgba(226, 227, 230, 0.3);
  --secondary_lightGrey_036: rgba(226, 227, 230, 0.36);
  --secondary_lightGrey_040: rgba(226, 227, 230, 0.4);
  --secondary_lightGrey_050: rgba(226, 227, 230, 0.5);
  --secondary_lightGrey_075: rgba(226, 227, 230, 0.75);
  --secondary_lightGrey_090: rgba(226, 227, 230, 0.9);
  --secondary_lightGrey_097: rgba(164, 164, 168, 0.97);
  --secondary_lightGrey_transparent: rgba(226, 227, 230, 0);
  --primary_grey: rgb(122, 122, 122);
  --primary-orange-color: #37a845;
  --primary-orange_010: #ff9a3c41;
  --primary-orange_050: #ff9a3c;
  --primary-orange_090: #ff9a3c;
  --primary_navyBlue: #01194f;
  --primary_blue: #0758b7;
  --primary_blue_010: #0759b750;
  --primary_lightblue: #039cfd;
  --primary_lightblue_010: #65c0f827;
  --primary_lightblue_050: #039dfd86;
  --primary_lightblue_090: #039dfdd8;
  --primary-bg-color: #fafafa;

  --color--green--100: #37a845;
  --color--green--90: #298834;
  --color--green--10: #18421d;
  --color--accent: var(--color--green--100);
  --color--accent--dark: var(--color--green--90);

  --linear-gradient--primary: linear-gradient(
    45deg,
    rgb(70, 238, 75) -30px,
    #0aca60
  );

  --box-shadow--primary--accent: rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(70, 238, 75) 0px 0px 0px 3px, rgb(0 0 0 / 12%) 0px 1px 1px 0px,
    rgb(186 190 210 / 20%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 12%) 0px 3px 9px 0px, rgb(0 0 0 / 8%) 0px 2px 5px 0px;

  --border-radius-small: 6px;
  --border-radius-profile-picture: 6px;
  --border-radius-medium: 12px;
  --border-radius-large: 16px;
  --border-radius-round: 50%;

  --font-size-xsmall: 14px;
  --font-size-small: 16px;
  --font-size-medium: 24px;
  --font-size-large: 32px;
  --font-size-xlarge: 40px;
  --font-size-xxlarge: 62px;

  --padding-xsmall: 5px;
  --padding-small: 10px;
  --padding-medium: 20px;
  --padding-large: 30px;
  --padding-xlarge: 40px;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

@layer base {
  html,
  body {
    font-family: 'Inter', sans-serif;
  }
}

html,
body {
  font-family: 'Inter', sans-serif;
  position: relative;
}

body {
  padding-bottom: env(safe-area-inset-bottom);
}
