.popup-overlay {
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  background-color: rgba(0, 0, 0, 0.35);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 300;

  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  overflow: scroll;
}

.dark-mode .popup-overlay {
  background-color: rgba(0, 0, 0, 0.45);
}

.popup-background-click {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.popup {
  width: 100%;
  background-color: white;
  border: 1px solid var(--secondary_lightGrey);
  border-radius: var(--border-radius-large);
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 2rem;

  @media screen and (max-width: 700px) {
    padding: 2rem;
  }

  @media screen and (max-width: 500px) {
    padding: 1rem;
  }

  &-btn {
    &__close {
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }
}

.popup-card {
  border-radius: 12px;
  box-shadow: var(--shadow-large-light);
  width: 100%;
  max-width: 600px;
  z-index: 1;
  background-color: white;
  position: relative;
  // overflow: hidden;

  &-large {
    max-width: 850px;
  }

  &-positioning {
    margin-top: 92px;
    margin-bottom: 92px;
  }
}

.dark-mode .popup-card {
  box-shadow: var(--shadow-large-dark);
}

.popup-closeicon-wrapper {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 30px;
  height: 30px;
  background-color: var(--white);
  box-shadow: var(--shadow-clickable);
  border-radius: var(--border-radius-small);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: box-shadow 200ms;
}
.popup-closeicon-wrapper:hover {
  box-shadow: var(--shadow-clickable-hover);
}
.popup-closeicon-wrapper:active {
  box-shadow: var(--shadow-clickable-active);
}

.popup-closeicon-icon {
  color: var(--black);
}

.popup-backicon-wrapper {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 28px;
  height: 28px;
  border: 1px solid var(--lightGrey2);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.popup-backicon-wrapper:hover {
  background-color: var(--lightGrey);
}

.popup-backicon-icon {
  color: var(--black);
}
