.cookies {
  position: relative;
  z-index: 100;

  &-overlay {
    &::after {
      content: '';
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 90;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
    }
  }

  &-box {
    position: fixed;
    bottom: 2rem;
    z-index: 1000;
    right: 2rem;
    display: grid;
    gap: 1.5rem;
    background-color: white;
    padding: 2rem;
    padding: 2rem;
    border-radius: 10px;

    @media (max-width: 620px) {
      bottom: 1rem;
      right: 1rem;
    }

    p {
      --FONT-SIZE--BODY: 14px;
      --LINE-HEIGHT--BODY: 25px;
      max-width: 250px;
      margin-bottom: 0;
    }

    &_buttons {
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }
  }
}
