.article {
  width: 100%;

  &-content {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--secondary_lightGrey);
    border-radius: 10px;
    background-color: white;
    width: 100%;

    h1 {
      font-size: var(--font-size-medium);
    }
  }

  &-image {
    object-fit: cover;
    width: 100%;
    height: 100%;

    &-container {
      overflow: hidden;
      height: 400px;
      width: 100%;
      border-radius: 10px;
      position: relative;

      @media screen and (max-width: 500px) {
        height: 200px;
      }

      h1 {
        font-size: var(--font-size-large);
        position: absolute;
        left: 2rem;
        bottom: 2rem;
        z-index: 100;
        color: white;

        @media screen and (max-width: 500px) {
          font-size: var(--font-size-small);
          left: 1rem;
          bottom: 1rem;
        }
      }

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgb(2, 0, 36);
        background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(0, 0, 0, 0.5452774859943977) 100%);
        opacity: 1;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

.contenful-textbox {
  p {
    margin-bottom: 0.75rem;
  }
}
