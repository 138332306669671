.login {
  width: 100vw;
  display: flex;
  justify-content: center;
  padding: 0 2rem;

  @media screen and (max-width: 500px) {
    padding: 0;
  }

  &-card {
    max-width: 600px;
    width: 100%;
    height: max-content;
    background-color: var(--white);
    border: 1px solid var(--secondary_lightGrey);
    border-radius: var(--border-radius-large);
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: start;

    @media screen and (max-width: 500px) {
      border-radius: 0;
      border: none;
      background-color: transparent;
    }

    &_header {
      width: 100%;
      padding: 1.5rem 0;
      border-bottom: 1px solid rgb(219, 219, 219);

      h1 {
        text-align: center;
      }
      p {
        text-align: center;
        padding: 5px;
      }
    }

    &_form {
      flex-direction: column;
      display: flex;
      width: 90%;
      margin-top: 2rem;
      padding: 0 0.75rem;
      margin-bottom: 1rem;

      input {
        border-radius: 5px;
      }

      input[type='submit'] {
        transition: 0.2s ease-in-out;
        color: white;
        font-weight: 600;
        font-size: 14px;

        &:hover {
          background-color: rgb(38, 38, 38);
        }
      }

      h1 {
        margin-bottom: 1rem;
      }

      @media screen and (max-width: 450px) {
        width: 100%;
      }
    }

    &_nav {
      width: 90%;
      padding: 0 0.75rem;

      @media screen and (max-width: 450px) {
        width: 100%;
      }

      a {
        color: black;
      }

      ul {
        padding-bottom: 2rem;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;

        li {
          @media screen and (max-width: 500px) {
            display: flex;
            flex-direction: column;
          }
        }

        li a {
          text-decoration: underline;
        }

        button {
          border: none;
          background-color: transparent;
          font-size: 16px;
          text-decoration: underline;
          color: rgb(26, 26, 145);
          cursor: pointer;
          font-family: 'Inter', sans-serif;
        }
      }

      &-upper {
        display: flex;
        gap: 1rem;

        @media screen and (max-width: 450px) {
          flex-direction: column;
          text-align: center;

          span {
            display: none;
          }
        }
      }

      @media screen and (max-width: 450px) {
        text-align: center;
      }
    }

    &_password-icon {
      position: absolute;
      right: 15px;
      cursor: pointer;
    }
  }
}
