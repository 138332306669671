.alertbar {
  background-color: black;
  min-width: 100%;
  z-index: 100;
  overflow: hidden;
  height: 40px;
  position: sticky;
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;

  span {
    text-align: center;
  }

  &:hover .alertbar_text-container {
    animation-play-state: paused;
  }

  &_slide {
    display: flex;
    position: relative;
  }
}

.alertbar_text-container {
  padding: 0.75rem;
  display: flex;
  // justify-content: space-between;
  justify-content: space-around;
  animation: animate 35s linear infinite;
  min-width: 100%;
  position: absolute;
  align-content: stretch;
  white-space: nowrap;

  --animation-delay: 35s;

  @media screen and (max-width: 450px) {
    animation: animate 25s linear infinite;
  }

  &--delay {
    transform: translateX(-100%);
    animation-delay: calc(var(--animation-delay) / 2);

    @media screen and (max-width: 450px) {
      animation-delay: calc(25 / 2);
    }
  }

  &-single {
    justify-content: flex-start;
  }
  p {
    margin-right: 1rem;
  }
}
.alertbar_disabled {
  display: none;
}

@keyframes animate {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
