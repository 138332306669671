.product-filter {
  max-width: 1100px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;

  select {
    border-color: rgb(209, 209, 209);
  }

  &_tags {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    gap: 1rem;

    @media screen and (max-width: 500px) {
      justify-content: center;
      gap: 0.5rem;
    }
  }

  &_tag {
    font-size: 18px;
    background-color: var(--white);
    padding: 0.75rem 1.25rem;
    border-radius: 3rem;
    border: 1px solid rgb(216, 216, 216);
    font-weight: 500;
    cursor: pointer;
    transition: box-shadow 0.25s ease-in-out;

    &:hover {
      box-shadow: var(--box-shadow--primary--accent);
    }

    &--active {
      background: var(--linear-gradient--primary);
      color: white;

      &:hover {
        box-shadow: none;
      }
    }

    @media screen and (max-width: 750px) {
      font-size: 16px;
      padding: 0.5rem 1rem;
    }
  }
}

.product-filter h2 {
  margin-bottom: 1rem;
}

.product-filter_container {
  display: flex;
}

.product-filter_form {
  display: flex;
  gap: 2rem;
  width: 80%;

  @media screen and (max-width: 750px) {
    flex-direction: column;
    width: 100%;
  }
}

.product-filter_input {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 750px) {
    order: -1;
    max-width: 100%;
  }
}

.product-filter_input input[type='text'] {
  padding-left: 40px;
  width: 100%;
  max-width: 400px;

  @media screen and (max-width: 750px) {
    max-width: 100%;
  }
}

.product-filter_input input[type='submit'] {
  border-radius: 0 5px 5px 0;
  border: 1px solid rgb(118, 118, 118);
  border-left: none;
}

.product-filter_input-icon {
  position: absolute;
  height: 40%;
  opacity: 0.7;
  left: 15px;
}

.product-filter_options {
  margin-left: auto;
}
