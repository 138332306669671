.faq-item {
  max-width: 45rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: start;
  padding: 3rem;
  background-color: var(--white);
  border-radius: var(--border-radius-large);
  border: 1px solid var(--secondary_lightGrey);
  cursor: pointer;
  transition: all 0.4s ease-in-out;

  &:hover {
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(61, 218, 61) 0px 0px 0px 2px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(186 190 210 / 20%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 3px 9px 0px, rgb(0 0 0 / 8%) 0px 2px 5px 0px;
  }
}

.faq-item_question {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 10px;
  width: 100%;
  z-index: 10;
}
.faq-item_question h2 {
  max-width: 80%;
  font-size: 24px;
}

.faq-item_answer {
  text-align: start;
  border-top: 1px solid var(--secondary_lightGrey);
  width: 100%;
  opacity: 0;
  height: 0;
}
.faq-item_answer-open {
  margin-top: 1rem;
  padding-top: 20px;
  padding-top: 2rem;
  opacity: 1;
  height: max-content;
}

.faq-item_answer p {
  font-size: var(--font-size-small);
  transition: 200ms ease-in-out;
  opacity: 0;
  max-height: 0;
}

.faq-item_answer #text {
  max-height: 0;
}

.faq-item_answer-open p {
  opacity: 1;
  max-height: max-content;
}

.faq-item_icon {
  height: 15px;
  margin-left: auto;
  transition: transform 200ms ease-in-out;
  align-content: center;
}

.open {
  transform: rotate(180deg);
}

@media screen and (max-width: 750px) {
  .faq-item {
    width: 90%;
  }
}

@media screen and (max-width: 500px) {
  .faq-item {
    padding: var(--padding-large);
  }

  .faq-item_question h2 {
    font-size: var(--font-size-small);
    max-width: 80%;
  }

  .faq-item_icon {
    height: 12px;
  }

  .faq-item_answer p {
    font-size: var(--font-size-xsmall);
  }
}
