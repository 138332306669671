header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgb(228, 228, 228);
  transition: background-color 0.3s;
  height: 75px;
  background-color: var(--primary-bg-color);
  z-index: 100;
  position: absolute;

  & > * {
    height: 100%;
  }

  @media (max-width: 500px) {
    height: auto;
    padding: 1rem;
  }

  @media (max-width: 1200px) and (min-width: 500px) {
    padding: 0 1rem;
  }
}

.header {
  &-container {
    height: 175px;

    @media (min-width: 500px) {
      height: 150px;
    }
  }

  &--fixed {
    position: fixed;
    top: 0;
    background-color: rgba(255, 255, 255, 0.247);
    border-bottom: 1px solid rgba(228, 228, 228, 0.342);

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
}
