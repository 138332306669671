.about {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-container {
  /* transform: translateY(-450px); */
}
.about-container h1 {
  margin-bottom: 1rem;
}
.about-container p {
  margin-bottom: 0.5rem;
}
