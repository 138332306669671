.admin-sidebar {
  align-items: center;
  z-index: 10;
  overflow: hidden;
  width: 220px;
  padding-top: var(--padding-large);
  padding-bottom: var(--padding-large);
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--primary_navyBlue);

  @media screen and (max-width: 1000px) {
    padding-top: 0;
    padding-bottom: 0;
    transform: translateX(-250px);
    margin-top: 75px;

    nav {
      order: -1;
    }
  }

  &-wrapper {
    height: 100vh;
  }

  @supports (-webkit-touch-callout: none) {
    padding-bottom: env(safe-area-inset-bottom);
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }

  h2 {
    color: white;
    margin-bottom: 2rem;
    font-weight: 500;
    font-size: var(--font-size-medium);

    @media screen and (max-width: 1000px) {
      display: none;
      margin-bottom: 0;
    }
  }

  &-overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 0.3;
    z-index: 10;
  }

  nav {
    width: 100%;

    ul {
      width: 100%;

      li {
        display: flex;
        width: 100%;
        padding: 1rem 1rem;
        transition: all 0.2s ease-in-out;
        font-size: var(--font-size-xsmall);
        justify-content: flex-start;
        align-items: center;

        a {
          justify-content: flex-start;
          transition: all 0.2s ease-in-out;
        }
      }

      li:hover {
        background-color: rgb(12, 34, 97);
      }
    }
  }

  &_nav-close {
    z-index: 110;
    color: white;
    height: 20px;
    cursor: pointer;
    padding: 10px;
    border-radius: 100%;
    transition: 200ms ease-in-out;

    &:hover {
      color: rgb(214, 214, 214);
      background-color: rgb(255, 255, 255, 0.05);
    }
  }

  &_nav-bottom {
    margin-top: auto;
  }

  &__button {
    &--logut {
      margin-top: auto;
    }
  }
}

.admin-sidebar nav ul {
  list-style-type: none;
}

.admin-sidebar nav ul li .nav-link {
  color: white;
}

.admin-sidebar_icon {
  margin-right: 0.5rem;
}

.nav-link-admin {
  color: white;
  padding: 0;
  border-radius: 0;

  &:hover {
    background-color: rgb(12, 34, 97);

    &:after {
      content: none;
    }
  }

  &-active {
    background-color: rgb(12, 34, 97);
  }
}
