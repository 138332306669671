.drawer-mobile-nav {
  .drawer-middle {
    a {
      padding: 15px 10px;
      display: block;
      font-weight: 600;
    }
    &-nav {
      a {
        display: flex;
        // align-items: center;
        gap: 10px;
        font-size: 18px;
      }

      .drawer-icon {
        width: 27px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
