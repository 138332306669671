.user {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 2rem;

  @media screen and (max-width: 950px) {
    padding: 0;
  }

  &_container {
    max-width: 1200px;
    width: 100%;
  }

  &_content {
    display: flex;

    @media screen and (max-width: 950px) {
      flex-direction: column;
    }
  }

  &_header {
    border-bottom: 1px solid #d7d7d7;
    padding-bottom: 1rem;

    @media screen and (max-width: 950px) {
      padding: 0 1rem;
      border-bottom: 0;
    }
  }

  &_section-container {
    border-radius: 10px;
    width: 100%;
    @media screen and (max-width: 950px) {
      border-radius: 0;
    }
  }
}

.user-menu {
  max-width: 250px;
  min-width: 210px;
  width: 100%;
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid #d7d7d7;
  background-color: white;
  margin-right: 2rem;
  max-height: 60vh;
  position: relative;

  @media screen and (max-width: 950px) {
    max-width: none;
    margin-bottom: 1rem;
  }

  &_collapsed {
    &-container {
      position: absolute;
      right: 0;
      top: 90px;
      background-color: white;
      padding: 1rem;
      border: 1px solid rgb(204, 204, 204);
      border-radius: 10px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      z-index: 10;
    }
  }

  nav {
    height: 100%;

    ul {
      display: flex;
      flex-direction: column;
      min-height: 500px;
      height: 100%;
      list-style: none;
      width: 100%;

      @media screen and (max-width: 950px) {
        min-height: 0;
        flex-direction: row;
        justify-content: space-between;
      }

      a {
        text-decoration: none;
      }

      li {
        margin-bottom: 0.5rem;
        padding: 0.75rem;
        border-radius: 10px;
        cursor: pointer;

        &:hover {
          background-color: #eeeeee;
        }

        &:hover a {
          color: black;
        }

        &:last-child {
          margin-top: auto;
        }

        @media screen and (max-width: 950px) {
          margin-bottom: 0;
          display: flex;
          flex-direction: column;
          font-size: 16px;
        }
      }
    }
  }

  &_icon {
    margin-right: 0.5rem;

    @media screen and (max-width: 950px) {
      font-size: 24px;
      margin-right: 0;
    }
  }

  &_nav-link {
    color: black;
    text-decoration: none;
    font-size: 18px;

    &--active {
      background-color: #eeeeee;
    }
  }
}

.user-verify {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  h1 {
    margin-bottom: 1rem;
  }
}

.user-table {
  border-radius: 8px;
  border: 1px solid #dbdbdb;
  margin-top: 2rem;
  font-size: 14px;
  width: 100%;
  border-spacing: 0;

  &_sticky-cell {
    position: sticky;
    position: -webkit-sticky;
    min-width: 100px;
    left: 0px;
    z-index: 5;

    @media screen and (max-width: 800px) {
      border-right: 1px solid rgb(224, 224, 224);
    }
    @media screen and (max-width: 500px) {
      max-width: 150px;
      overflow: hidden;
    }
  }

  &_container {
    overflow-y: hidden;
    overflow-x: auto;
    position: relative;
  }

  &_item-button {
    border: none;
    background: none;
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    cursor: pointer;

    &--closed {
      transition: transform 0.2s ease-in-out;
    }

    &--open {
      transform: rotate(180deg);
      transition: transform 0.2s ease-in-out;
    }
  }

  thead {
    background-color: #f3f3f3;
    width: 100%;
    margin-bottom: 0.5rem;
    tr {
      th {
        background-color: #f3f3f3;
        padding: 0.5rem 0.5rem;
        text-align: start;
        min-width: 100px;
      }
    }
  }

  tbody {
    tr {
      td {
        border-bottom: 1px solid #dbdbdb;
        padding: 1rem 0.5rem;
        height: max-content;
        background-color: #ffffff;
      }
    }

    .user-table_subtree {
      line-height: 0;
      opacity: 0;

      td {
        border-top: none;
        padding: 0;
        font-size: 12px;
        border-bottom: none;
        opacity: 1;
      }

      &--last {
        td {
          border-bottom: 1px solid #dbdbdb;
        }
      }

      &--open {
        opacity: 1;
        line-height: 16px;

        td {
          padding: 0.5rem 0.5rem;
        }
      }
    }

    tr:last-child .user-table_subtree {
      td {
        border-bottom: none;
      }
    }
  }

  &_bold {
    font-weight: 600;
  }

  &_tag {
    padding: 0.1rem 0.4rem;
    border-radius: 0.5rem;
    color: white !important;
    word-break: keep-all;
    font-size: 14px;

    &--active {
      background-color: #8fc87b;
    }

    &--pending {
      background-color: #e7c607;
    }

    &--inactive {
      background-color: rgb(204, 75, 75);
    }
  }

  &_empty-table {
    position: absolute;
    width: max-content;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 0%);
    // margin-top: 4rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    img {
      height: 50px;
      opacity: 0.6;
      margin-bottom: 10px;
    }

    p {
      max-width: 250px;
      text-align: center;
    }
  }
}
