.admin-adduser {
  position: relative;

  form {
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    gap: 1rem;

    h4 {
      margin-bottom: 0.5rem;
    }
  }

  &__header {
    display: flex;
    // align-items: center;
    label {
      margin-left: 2rem;
      align-self: center;
    }

    @media screen and (max-width: 500px) {
      flex-direction: column;
      label {
        margin-left: 0;
        align-self: center;
      }
    }
  }

  &_customer-search {
    width: 100%;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgb(214, 214, 214);

    &--active {
    }

    &-input {
      width: 100%;

      &--active {
        border-radius: 5px 5px 0 0;
      }

      &-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        cursor: pointer;

        &:hover {
          color: rgb(134, 134, 134);
        }
      }
    }

    .input-dropdown-container {
      position: relative;
      width: 100%;

      @media screen and (max-width: 1000px) {
        width: 100%;
      }
    }
  }

  &_dropdown {
    width: 100%;
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    border-top: none;
    z-index: 100;
    background-color: white;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;

    ul {
      list-style-type: none;

      li {
        padding: 0.5rem 0.75rem;
        border-bottom: 1px solid rgb(204, 204, 204);
        cursor: pointer;
        display: flex;
        gap: 0.5rem;
        color: grey;
      }
    }

    &-item {
      &--name {
        color: rgb(32, 32, 32);
        font-weight: 500;
      }
      &:nth-child(odd) {
        background-color: rgb(245, 245, 245);
      }
    }
  }

  &_password-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:hover {
      color: rgb(134, 134, 134);
    }
  }

  .add-user-roles {
    display: flex;
    gap: 1rem;

    &__item {
      padding: 0.5rem 1rem;
      cursor: pointer;
      border: 1px solid rgb(199, 199, 199);
      transition: 0.3s ease-in-out;
      border-radius: 4px;

      &:hover {
        background-color: rgb(245, 245, 245);
      }

      &--active {
        background-color: rgb(220, 220, 220);
      }
    }
  }
}
