.product-modal {
  background-color: var(--primary-bg-color);
  border-radius: var(--border-radius-large);
  display: flex;
  flex-direction: column;

  &_container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgb(0, 0, 0, 0.2);
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  &_main {
    min-width: 500px;
    min-height: 500px;
    display: flex;

    @media screen and (max-width: 500px) {
      min-width: 100%;
      min-height: 100%;
      flex-direction: column;
    }

    h3 {
      margin-top: 0.5rem;
    }

    button {
      margin-top: auto;
    }
  }

  &_img {
    object-fit: cover;
    height: 100%;
    width: 100%;

    @media screen and (max-width: 500px) {
    }

    &-container {
      width: 100%;
      max-width: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      overflow: hidden;
      border-radius: var(--border-radius-medium) 0 0 var(--border-radius-medium);

      @media screen and (max-width: 500px) {
        max-width: 100%;
        max-height: 300px;
        border-radius: var(--border-radius-medium) var(--border-radius-medium) 0 0;
      }

      &--error {
        border: 1px solid rgb(213 213 213);
        background-color: #dadada;
        height: auto;
        padding: 2rem 0;
      }

      p {
        color: white;
        font-size: 28px;
        font-weight: 600;
      }
    }

    &--error {
      height: 40%;
      width: 100%;
      object-fit: contain;

      @media screen and (max-width: 500px) {
        width: 40%;
      }
    }
  }
}

.product-modal .product-modal button {
  margin-top: 1rem;
}

.product-modal_header {
  padding: 5px;
  width: 100%;
}

.product-modal_close {
  height: 40px;
  width: 40px;
  border-radius: var(--border-radius-round);
  background-position: center;
  background-size: 40%;
  background-repeat: no-repeat;
  padding: 1rem;
  background-image: url('../../../assets/icons/close-icon-naked-3.png');
  margin-left: auto;
  transition: background-color 200ms ease-in-out;
  cursor: pointer;
}

.product-modal_close:hover {
  opacity: 0.6;
}
