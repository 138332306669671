.section-signup {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;

  &-wrapper {
    max-width: 1200px;
    width: 100%;
    background-color: #f2f2f2;
    border-radius: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 950px) {
      grid-template-columns: 1fr;
    }

    @media (max-width: 620px) {
      gap: 2rem;
      border-radius: 1rem;
      min-height: 0;
    }
  }

  &-left {
    display: flex;
    flex-direction: column;
    padding: 4rem;

    @media screen and (max-width: 950px) {
      align-items: center;
      text-align: center;
    }

    @media (max-width: 620px) {
      padding: 2rem 1rem;
    }

    h2 {
      font-size: 35px;
      max-width: max-content;
      position: relative;
      margin-bottom: 1.5rem;

      @media (max-width: 620px) {
        font-size: 25px;
      }

      &::after {
        content: '';
        background: var(--color--accent);
        position: absolute;
        bottom: -5px;
        left: 0;
        height: 4px;
        width: 40%;
      }
    }

    p {
      max-width: 500px;
      font-size: 18px;
      margin-bottom: 2rem;

      @media screen and (max-width: 950px) {
        margin-bottom: 1.5rem;
      }

      @media (max-width: 620px) {
        font-size: 16px;
      }
    }
  }

  &-right {
    width: 100%;
    align-self: flex-end;
    justify-self: center;
    padding: 0 1rem;

    @media screen and (max-width: 950px) {
      max-width: 500px;
    }
    @media screen and (max-width: 500px) {
      // display: none;
    }
  }
}
