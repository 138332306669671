.policy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.policy-container {
  /* transform: translateY(-450px); */
}
.policy-container h2 {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}
.policy-container p {
  margin-bottom: 0.5rem;
}
