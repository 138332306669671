.article-card {
  width: 100%;
  aspect-ratio: 2 /2;
  background-color: var(--white);
  border-radius: var(--border-radius-large);
  border: 1px solid var(--secondary_lightGrey);
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  max-height: 350px;
  position: relative;

  h2 {
    font-size: var(--font-size-small);
    margin-bottom: 0.25rem;
    color: white;
  }

  @media (max-width: 620px) {
    width: 100%;
  }

  @media (max-width: 500px) {
    width: 100%;
  }

  &_img {
    position: relative;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: height 300ms ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary_lightGrey);

    &--error {
      background-size: 50%;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgb(2, 0, 36);
      background: -moz-linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(0, 0, 0, 0.5452774859943977) 100%);
      background: -webkit-linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(0, 0, 0, 0.5452774859943977) 100%);
      background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(0, 0, 0, 0.5452774859943977) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#000000",GradientType=1);
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }

    img {
      width: 100%;
      height: 100%;
    }

    h4 {
      color: rgb(233, 233, 233);
      font-size: var(--font-size-small);
      opacity: 0;
      transition: all 0.35s ease-in-out;
      z-index: 50;
      font-weight: 300;
      cursor: pointer;
    }
  }

  &_lower {
    padding: 0.5rem 1rem;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  &_text {
    opacity: 0;
    transition: opacity 500ms ease-in;
  }

  &:hover {
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(70, 238, 75) 0px 0px 0px 3px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(186 190 210 / 20%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 3px 9px 0px, rgb(0 0 0 / 8%) 0px 2px 5px 0px;
  }
}
