.sell-info {
  padding: 0 1rem;

  h2 {
    text-align: center;
  }
}

.tip-container {
  display: grid;
  grid-template-columns: minmax(10%, 50px) 1fr;
  margin-top: var(--padding-large);
}

.tip-icon {
  background-color: var(--primary-orange-color);
  color: white;
  height: 10px;
  width: 10px;
  border-radius: var(--border-radius-round);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: var(--font-size-small);
  /* border: 1px solid var(--secondary_lightGrey); */
}

.tip-text-container h3 {
  font-size: 18px;
  font-weight: 600;
}

.tip-text-container p {
  margin-bottom: 1rem;
}

.tips-container {
  display: grid;
  grid-template-columns: minmax(10%, 25px) 1fr;
}

@media screen and (min-width: 500px) {
  .tip-icon {
    height: 20px;
    width: 20px;
    font-size: var(--font-size-medium);
    padding: var(--padding-medium);
  }

  .sell-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .sell-info h2 {
    font-size: var(--font-size-large);
  }

  .sell-info p {
    font-size: var(--font-size-small);
  }

  .sell-info-tips {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  .tip-container {
    width: 90%;
    max-width: 600px;
  }

  .tip-text-container h3 {
    font-size: var(--font-size-medium);
  }

  .tip-text-container h4 {
    font-size: var(--font-size-small);
  }

  .tip-text-container p {
    margin-bottom: 1rem;
  }

  .tips-icon {
    display: flex;
    justify-content: center;
  }
}
