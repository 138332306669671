.workshop {
  &-hero {
    .hero-text_img {
      background-image: image-set(
        url('https://images.unsplash.com/photo-1644854407965-d7adffb0edac?q=80&w=2831&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D') 1x,
        url('https://images.unsplash.com/photo-1644854407965-d7adffb0edac?q=80&w=5662&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D') 2x
      );
      background-position: bottom;
    }
  }

  &-intro {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2rem;
    margin-bottom: 2rem;

    @media screen and (max-width: 500px) {
      padding: 0 1rem;
    }

    p {
      max-width: 750px;
      font-size: 16px;
      margin-bottom: 0.5rem;
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      margin-bottom: 10px;
      margin-left: 2px;
      font-weight: 700;
    }

    @media (max-width: 1000px) {
      padding: 0 1rem;
    }

    &-box {
      width: 100%;
      max-width: 1000px;
      padding: 2.5rem;
      border-radius: var(--border-radius-medium);
      border: 1px solid var(--secondary_lightGrey);
      background-color: white;

      @media screen and (max-width: 500px) {
        padding: 1rem;
      }

      h2 {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;

        @media screen and (max-width: 500px) {
          font-size: 1.25rem;
        }
      }
    }

    &-category {
      &:not(:last-child) {
        margin-bottom: 3rem;
      }

      h3 {
        margin-bottom: 0.5rem;
        font-weight: 600;
        text-transform: capitalize;
        font-size: 1.2rem;

        @media screen and (max-width: 500px) {
          font-size: 1.1rem;
        }
      }
    }
  }
}

.workshop-list-item {
  margin-bottom: 0.5rem;

  h4 {
    font-weight: 500;
  }

  @media screen and (max-width: 700px) {
    h4 {
      font-size: 14px;
    }
  }

  &-header {
    display: flex;
  }

  &-footer {
    border-left: 2px solid rgb(170, 170, 170);
    transition: height 0.3s ease-in-out;

    &--open {
      height: 25px;
      width: 100%;
      opacity: 1;
      padding: 5px 0 20px 0.5rem;
    }

    &--closed {
      height: 0px;
      width: 0;
      opacity: 0;
    }
  }

  &-btn {
    background: none;
    border: none;
    margin-left: 1rem;
    cursor: pointer;
    padding: 5px;

    &:hover {
      background-color: rgb(241, 241, 241);
    }
  }

  &-price {
    word-break: keep-all;
    margin-left: auto;

    @media screen and (max-width: 700px) {
      font-size: 14px;
    }
  }
}
