.product-sell-info {
  .popup {
    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.5rem;

      &__wrapper {
        width: 200px;
        aspect-ratio: 1/1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @media screen and (max-width: 500px) {
          width: 100%;
          height: 300px;
          aspect-ratio: 0;
        }
      }

      &--error {
        object-fit: contain;
        max-height: 75%;

        @media screen and (max-width: 500px) {
          max-height: 50%;
        }
      }
    }

    &-text {
      &__wrapper {
        width: 100%;
        align-self: flex-end;

        .dot {
          max-width: 6px;
          max-height: 6px;
          min-width: 6px;
          min-height: 6px;
          background-color: black;
          border-radius: 1000px;

          @media screen and (max-width: 500px) {
            display: none;
          }
        }

        span {
          color: rgb(129, 129, 129);
        }
      }
    }

    &-bottom {
      .user-table {
        @media screen and (max-width: 500px) {
          margin-top: 1rem;
        }
      }
    }
  }
}
