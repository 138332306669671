.store-hero {
  .hero-text {
    &_img {
      background-position: top;
      background-image: url('https://images.unsplash.com/photo-1688619103478-5a38a2ff8a03?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
      // background-image: url('../../assets/imgs/ski-equipment.jpg');
    }
  }
}

.store-opening-hours {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.store-opening-hours_wrapper {
  font-size: 20px;
  min-height: 20vh;
  min-height: 200px;
  text-align: center;
  display: flex;
  justify-content: center;

  padding: 2rem;

  @media screen and (max-width: 1000px) {
    padding: 1rem;
  }
}

.store-opening-hours_wrapper h1 {
  margin-bottom: 2rem;
  font-size: 35px;
  font-weight: 600;

  @media screen and (max-width: 500px) {
  }
}

.store-opening-hours_container {
  background-color: #f2f2f2;
  max-width: 1200px;
  width: 100%;
  border-radius: 1rem;
  margin-top: 2rem;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 620px) {
    margin-top: 0;
  }

  @media screen and (max-width: 750px) {
    border-radius: 0.5rem;
  }
}

.store-opening-hour {
  border-radius: 1rem;
  border-width: 1px;
  border-style: solid;
  border-color: #888888;
  min-width: 240px;
  padding: 0.5rem;
}

.store-opening-hours {
  p {
    font-size: 32px;
    font-weight: 600;
  }

  h2 {
    font-size: 18px;
    font-weight: 300;
  }

  @media screen and (max-width: 500px) {
    p {
      font-size: var(--font-size-medium);
    }

    h2 {
      font-size: var(--font-size-small);
    }
  }
}

.store-special-hours-container {
  min-height: 20vh;
  min-height: 200px;
  padding: 2rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.store-special-hours-container h2 {
  margin-bottom: 1rem;
  font-size: 26px;
  font-weight: 600;
}

.store-special-hours {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;

  &--error {
    font-weight: 500;
    font-size: 18px;
  }

  h3 {
    font-size: 18px;
    font-weight: 300;
  }

  p {
    font-size: 24px;
    font-weight: 600;
  }
}

.store-newproducts {
  transform: translateY(0);
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
}

.store-newproducts h1 {
  margin-bottom: 1rem;
  margin-left: 60px;
}

.store-newproducts-container {
  padding: 0 2rem;
  max-width: 1200px;
  width: 100%;
}

.slider {
  display: flex;
  height: 200px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.slide-track {
  width: calc(250px * 20);
  display: flex;
  animation: scroll 10s linear infinite;
  width: 100%;
  position: absolute;

  --animation-delay: 10s;

  &--delay {
    transform: translateX(-100%);
    animation: scroll 10s linear infinite;
    animation-delay: calc(var(--animation-delay));
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 20));
  }
}

@keyframes delayedScroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.store-info {
  text-align: center;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.store-info_container {
  margin-top: 2rem;
  width: 90%;
  max-width: 800px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.store-info_item {
  min-width: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.store-info_item p {
  font-size: var(--font-size-small);
}
.store-info_icon {
  height: 50px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.75;
  width: 100%;
}

@media screen and (max-width: 750px) {
  .store-info_container {
    grid-template-columns: 1fr;
  }
  .store-info_item {
    margin-bottom: 2rem;
  }
  .store-info_icon {
    height: 3rem;
  }
  .store-info_item h2 {
    font-size: var(--font-size-small);
  }
}

.store-info {
  &-section {
    display: flex;
    justify-content: center;
    padding: 1rem;
  }

  &-box {
    display: flex;
    max-width: 1200px;
    width: 100%;
    background-color: white;
    border-radius: 1rem;
    border: 1px solid #e1e0e0;

    @media screen and (max-width: 750px) {
      flex-direction: column;
      border-radius: 0.5rem;
    }
  }

  &-map {
    flex: 1 1 50%;
    border-radius: 1rem 0 0 1rem;

    @media screen and (max-width: 750px) {
      height: 300px;
      flex: auto;
      order: 2;
      border-radius: 0 0 0.5rem 0.5rem;
    }
  }
}

.info-textbox {
  padding: 3rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media screen and (max-width: 750px) {
    width: 100%;
  }

  h2 {
    font-size: 30px;
    position: relative;
    max-width: max-content;

    &::after {
      content: '';
      background: var(--color--accent);
      position: absolute;
      bottom: -5px;
      left: 0;
      height: 4px;
      width: 50%;
    }
  }

  h3 {
    font-size: 2em;
    margin-bottom: 0.5em;
  }

  &_item {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;

    &-icon {
      min-height: 25px;
      min-width: 25px;
    }

    p {
      font-size: 20px;
      font-weight: 600;
    }
  }
}
