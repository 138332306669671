.product-modal {
  background-color: var(--primary-bg-color);
  // border: 1px solid var(--secondary_lightGrey);
  border-radius: var(--border-radius-large);
  display: flex;
  flex-direction: column;

  &_container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgb(0, 0, 0, 0.2);
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
}

.product-modal .product-modal button {
  margin-top: 1rem;
}

.product-modal_header {
  padding: 5px;
}

.product-modal_close {
  height: 40px;
  width: 40px;
  border-radius: var(--border-radius-round);
  background-position: center;
  background-size: 40%;
  background-repeat: no-repeat;
  padding: 1rem;
  background-image: url('../../../assets/icons/close-icon-naked-3.png');
  margin-left: auto;
  transition: background-color 200ms ease-in-out;
  cursor: pointer;
}

.product-modal_close:hover {
  opacity: 0.6;
}
